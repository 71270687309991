// My imgs
export const nblogo = require('../images/nb-logo.png');
export const aboutImage = require('../images/about-image.png');

// Portfolio showcase imgs
export const sunshot = require('../images/sunshot-showcase.png');
export const famfiction = require('../images/famfiction-showcase.png');
export const vanhornContractors = require('../images/vanhorn-contractors-showcase.png');
export const comingsoon = require('../images/coming-soon.png');

// Work history imgs
export const webstaurantLogo = require('../images/webstaurantstore-logo.png');
export const clarityLogo = require('../images/clarity-logo.jpg');
export const anovaaLogo = require('../images/anovaa-logo.png');
export const millersvilleLogo = require('../images/millersville-logo.jpg');

// Icons
export const codeIcon = <i className="fa-solid fa-code"></i>;
export const laptopCodeIcon = <i className="fa-solid fa-laptop-code"></i>;
export const cropIcon = <i className="fa-solid fa-crop-simple"></i>;
export const upRightSquareIcon = <i className="fa-solid fa-up-right-from-square"></i>;
export const paperPlaneIcon = <i className="fa-solid fa-paper-plane"></i>;
export const phoneIcon = <i className="fa-solid fa-square-phone"></i>;
export const githubIcon = <i className="fa-brands fa-github"></i>;
export const linkedInIcon = <i className="fa-brands fa-linkedin"></i>;